// Font Awesome
// Font icon set

import { config, library, dom } from "@fortawesome/fontawesome-svg-core";

// Change the config to fix the flicker
config.mutateApproach = "sync";

import {
  faSearch,
  faTimes,
  faAngleRight,
  faPhoneAlt,
  faAngleLeft,
  faMapMarkerAlt,
  faEnvelope,
  faPrint,
  faPlus,
  faMinus,
  faAngleDown
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faPinterestP,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faSearch,
  faTimes,
  faAngleRight,
  faPhoneAlt,
  faAngleLeft,
  faMapMarkerAlt,
  faEnvelope,
  faFacebookF,
  faPinterestP,
  faInstagram,
  faPrint,
  faPlus,
  faMinus,
  faAngleDown
);

dom.watch();
