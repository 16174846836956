$(document).ready(() => {
  let prevScrollpos = window.pageYOffset;

  $(window).on("scroll", function() {
    if ($(this).scrollTop() > 50) {
      if (!$(".navbar").hasClass("navbar--scrolled")) {
        $(".navbar").addClass("navbar--scrolled");
      }
      let currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        $(".navbar").removeClass("navbar--hidden");
      } else {
        $(".navbar").addClass("navbar--hidden");
      }
      prevScrollpos = currentScrollPos;
    } else {
      if ($(".navbar").hasClass("navbar--scrolled")) {
        $(".navbar").removeClass("navbar--scrolled");
      }
    }
  });

  // menu
  const menuOpen = () => {
    $("body").css("overflow", "hidden");
    $("#menu").addClass("menu--opened");
    $(".navbar").addClass("navbar--opened");
    $(".navbar").removeClass("navbar--scrolled");
    $("#search").removeClass("search--opened");
    $("#searchOpen").show();
    $("#searchClose").hide();
    // $(".navbar").css("transform", "none");
  };

  const menuClose = () => {
    $("#menu").removeClass("menu--opened");
    $(".navbar").removeClass("navbar--opened");
    if ($(window).scrollTop() > 50) {
      $(".navbar").addClass("navbar--scrolled");
    }
    setTimeout(function() {
      $("body").css("overflow", "auto");
      // if ($(window).scrollTop() > 50) {
      //   $(".navbar").addClass("navbar--scrolled");
      // } else {
      //   $(".navbar").removeClass("navbar--scrolled");
      // }
    }, 660);
  };

  $(".hamburger").on("click", function() {
    $(this).toggleClass("hamburger--clicked");
    $(".navbar").removeClass("navbar--hidden");
    if ($(this).hasClass("hamburger--clicked")) {
      menuOpen();
    } else {
      menuClose();
    }
  });

  // search

  const searchOpen = () => {
    $("body").css("overflow", "hidden");
    $("#search").addClass("search--opened");
    $("#searchOpen").hide();
    $(".navbar").addClass("navbar--opened");
    $(".navbar").removeClass("navbar--scrolled");
    $("#searchClose").show();
    $(".search__form input").focus();
    $("#menu").removeClass("menu--opened");
    $(".hamburger").removeClass("hamburger--clicked");
    $(".search__form input").focus();
  };

  const searchClose = () => {
    $("body").css("overflow", "auto");
    $("#search").removeClass("search--opened");
    $("#searchOpen").show();
    $("#searchClose").hide();
    $(".navbar").removeClass("navbar--opened");
    if ($(window).scrollTop() > 50) {
      $(".navbar").addClass("navbar--scrolled");
    }
  };

  $("#searchBtn").on("click", function() {
    $(this).toggleClass("search__btn--clicked");
    if ($(this).hasClass("search__btn--clicked")) {
      searchOpen();
    } else {
      searchClose();
    }
  });
});
